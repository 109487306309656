import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // Import custom CSS for extra color & animations
// import VoiceChat from "./VoiceChat"; // Uncomment if you have a VoiceChat component

const BASE_URL = "https://hdkb.online/api";

const PlayerPage = () => {
  const [playerName, setPlayerName] = useState("");
  const [playerId, setPlayerId] = useState("");
  const [word, setWord] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [message, setMessage] = useState("");
  const [allPlayers, setAllPlayers] = useState({});
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [voteResult, setVoteResult] = useState(null);

  const loginPlayer = async () => {
    if (!playerName) return;
    try {
      const response = await axios.post(`${BASE_URL}/player-login`, { player_name: playerName });
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setPlayerId(response.data.player_id);
        setWord(response.data.word);
        setLoggedIn(true);
        setMessage("");
      }
    } catch (error) {
      setMessage("Login failed. Try again.");
    }
  };

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get-players`);
      setAllPlayers(response.data.players);
      if (playerId in response.data.players) {
        setWord(response.data.players[playerId].word);
      }
      setVoteResult(response.data.vote_result);
    } catch (error) {
      console.error("Error fetching players");
    }
  };

  const castVote = async () => {
    if (!selectedCandidate) {
      setMessage("Please select a candidate to vote for.");
      return;
    }
    try {
      const candidateName = allPlayers[selectedCandidate].name;
      const response = await axios.post(`${BASE_URL}/vote`, {
        voter_id: playerId,
        voted_id: selectedCandidate,
      });
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setMessage(`You voted for ${candidateName}`);
      }
    } catch (error) {
      setMessage("Voting failed. Try again.");
    }
  };

  useEffect(() => {
    let interval;
    if (loggedIn) {
      fetchPlayers();
      interval = setInterval(fetchPlayers, 3000);
    }
    return () => clearInterval(interval);
  }, [loggedIn, playerId]);

  const renderVoteResult = () => {
    if (!voteResult) return null;
    if (voteResult.tie) {
      return (
        <div className="mt-4">
          <h5 className="text-primary">Vote Result: Tie</h5>
          {voteResult.candidates.map((candidate) => (
            <p key={candidate.voted_player_id}>
              {candidate.voted_player_name} - {candidate.votes} vote{candidate.votes > 1 ? "s" : ""} ({candidate.role})
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <div className="mt-4">
          <h5 className="text-primary">
            Vote Result: <strong>{voteResult.voted_player_name}</strong> ({voteResult.votes} vote{voteResult.votes > 1 ? "s" : ""}) � Identified as <strong>{voteResult.role}</strong>
          </h5>
        </div>
      );
    }
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Player Page</h2>
      {message && <div className="alert alert-danger text-center">{message}</div>}
      {!loggedIn ? (
        <div className="row justify-content-center">
          <div className="col-md-6 col-sm-8">
            <div className="card p-4 mb-4">
              <div className="card-body">
                <h4 className="card-title text-center">Login</h4>
                <div className="form-group">
                  <label htmlFor="playerName">Enter your name</label>
                  <input
                    type="text"
                    id="playerName"
                    className="form-control"
                    placeholder="Your name"
                    value={playerName}
                    onChange={(e) => setPlayerName(e.target.value)}
                  />
                </div>
                <button onClick={loginPlayer} className="btn btn-primary btn-custom btn-block">
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="card mb-4">
            <div className="card-body text-center">
              <h4>
                Welcome, <strong>{playerName}</strong>!
              </h4>
              <h5>Your Word: <strong>{word || "Not assigned yet"}</strong></h5>
            </div>
          </div>
          <div className="card p-4 mb-4">
            <div className="card-body">
              <h5>Vote for a player:</h5>
              {Object.keys(allPlayers).length === 0 ? (
                <p>No players available for voting.</p>
              ) : (
                <div className="form-group">
                  <select
                    className="form-control"
                    value={selectedCandidate}
                    onChange={(e) => setSelectedCandidate(e.target.value)}
                  >
                    <option value="">--Select a player--</option>
                    {Object.entries(allPlayers)
                      .filter(([id]) => id !== playerId)
                      .map(([id, info]) => (
                        <option key={id} value={id}>
                          {info.name}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              <button onClick={castVote} className="btn btn-success btn-custom">
                Vote
              </button>
            </div>
          </div>
          {renderVoteResult()}
          {/* Optionally include the VoiceChat component here */}
        </>
      )}
    </div>
  );
};

export default PlayerPage;
