import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ManagementConsole from "./components/ManagementConsole";
import PlayerPage from "./components/PlayerPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/mngt" element={<ManagementConsole />} />
        <Route path="/" element={<PlayerPage />} />
      </Routes>
    </Router>
  );
}

export default App;
