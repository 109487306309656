import React, { useState, useEffect } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./custom.css"; // Custom styles for colorful effects

const BASE_URL = "https://hdkb.online/api";

const ManagementConsole = () => {
  const [players, setPlayers] = useState({});
  const [maxPlayers, setMaxPlayers] = useState("4");
  const [showResetOptions, setShowResetOptions] = useState(false);
  const [message, setMessage] = useState("");
  const [showWords, setShowWords] = useState(false);
  const [voteResult, setVoteResult] = useState(null);

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/get-players`);
      setPlayers(response.data.players);
      setVoteResult(response.data.vote_result);
    } catch (error) {
      console.error("Error fetching players:", error);
    }
  };

  const startNewRound = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/start-round`);
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setMessage(response.data.message);
        setPlayers(response.data.players);
        setVoteResult(null);
      }
    } catch (error) {
      console.error("Error starting new round:", error);
    }
  };

  const initiateNewGame = () => {
    setPlayers({});
    setShowResetOptions(true);
    setMessage("");
    setVoteResult(null);
  };

  const startNewGame = async () => {
    try {
      const response = await axios.post(`${BASE_URL}/reset-game`, {
        numPlayers: parseInt(maxPlayers, 10),
      });
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setMessage(response.data.message);
        setShowResetOptions(false);
        setPlayers({});
        setVoteResult(null);
      }
    } catch (error) {
      console.error("Error starting new game:", error);
    }
  };

  const viewVotes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/view-votes`);
      if (response.data.error) {
        setMessage(response.data.error);
      } else {
        setMessage(response.data.message);
        setVoteResult(response.data.vote_result);
      }
    } catch (error) {
      console.error("Error viewing votes:", error);
    }
  };

  useEffect(() => {
    fetchPlayers();
    const interval = setInterval(fetchPlayers, 5000);
    return () => clearInterval(interval);
  }, []);

  const renderTable = () => {
    const playerEntries = Object.entries(players);
    if (playerEntries.length === 0) {
      return <p className="text-center">No players connected.</p>;
    }
    return (
      <div className="table-responsive">
        <table className="table table-bordered table-hover text-center">
          <thead>
            <tr>
              <th>Player ID</th>
              <th>Player Name</th>
              <th>Status</th>
              {showWords && <th>Assigned Word</th>}
            </tr>
          </thead>
          <tbody>
            {playerEntries.map(([playerId, info]) => (
              <tr key={playerId}>
                <td>{playerId}</td>
                <td>{info.name}</td>
                <td>
                  {info.loggedIn ? (
                    <span className="text-success font-weight-bold">Active</span>
                  ) : (
                    <span className="text-danger font-weight-bold">Disconnected</span>
                  )}
                </td>
                {showWords && <td>{info.word || "N/A"}</td>}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderVoteResult = () => {
    if (!voteResult) return null;
    if (voteResult.tie) {
      return (
        <div className="mt-4">
          <h5 className="text-primary">Vote Result: Tie</h5>
          {voteResult.candidates.map((candidate) => (
            <p key={candidate.voted_player_id}>
              {candidate.voted_player_name} - {candidate.votes} vote{candidate.votes > 1 ? "s" : ""} ({candidate.role})
            </p>
          ))}
        </div>
      );
    } else {
      return (
        <div className="mt-4">
          <h5 className="text-primary">
            Vote Result: <strong>{voteResult.voted_player_name}</strong> ({voteResult.votes} vote{voteResult.votes > 1 ? "s" : ""}) � Identified as <strong>{voteResult.role}</strong>
          </h5>
        </div>
      );
    }
  };

  return (
    <div className="container my-4">
      <h2 className="text-center mb-4">Management Console</h2>
      {message && <div className="alert alert-info text-center">{message}</div>}
      {showResetOptions ? (
        <div className="card p-3 mb-4">
          <div className="form-group">
            <label htmlFor="maxPlayers">Select number of players:</label>
            <select
              id="maxPlayers"
              className="form-control"
              value={maxPlayers}
              onChange={(e) => setMaxPlayers(e.target.value)}
            >
              {Array.from({ length: 8 }, (_, index) => {
                const num = index + 3;
                return (
                  <option key={num} value={num}>
                    {num}
                  </option>
                );
              })}
            </select>
          </div>
          <button onClick={startNewGame} className="btn btn-success btn-custom mr-2">
            Start New Game
          </button>
          <button onClick={() => setShowResetOptions(false)} className="btn btn-secondary btn-custom">
            Cancel
          </button>
        </div>
      ) : (
        <div className="text-center mb-4">
          <button onClick={startNewRound} className="btn btn-primary btn-custom mr-2">
            Start New Round
          </button>
          <button onClick={initiateNewGame} className="btn btn-warning btn-custom mr-2">
            New Game
          </button>
          <button onClick={() => setShowWords(!showWords)} className="btn btn-info btn-custom mr-2">
            {showWords ? "Hide Words" : "Show Words"}
          </button>
          <button onClick={viewVotes} className="btn btn-dark btn-custom">
            View Votes
          </button>
        </div>
      )}
      {renderTable()}
      {renderVoteResult()}
    </div>
  );
};

export default ManagementConsole;
